import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const ArrowCircleRight: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm3.244-7.619-2.625-2.625A.875.875 0 1 0 9.38 7.994l1.132 1.131H7.375a.875.875 0 1 0 0 1.75h3.138L9.38 12.006a.875.875 0 1 0 1.238 1.238l2.625-2.625a.875.875 0 0 0 0-1.238Z"
        fill="inherit"
      />
    </_BlankIcon>
  );
};

export default ArrowCircleRight;
