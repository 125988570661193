import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const MenuTiltedIcon: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 30 20" {...props}>
      <path d="m0,15.125l20,0l0,5l-20,0l0,-5z" fill="inherit" />
      <path d="m10,0l20,0l0,5l-20,0l0,-5z" fill="inherit" />
    </_BlankIcon>
  );
};

export default MenuTiltedIcon;
