import "react-tooltip/dist/react-tooltip.css";

import type { FC, PropsWithChildren, ReactElement } from "react";
import React, { Children } from "react";
import { useMemo } from "react";
import { cloneElement } from "react";
import type { ITooltip } from "react-tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";

import uuid from "../utils/uuid";

export type TooltipProps = Omit<ITooltip, "id" | "content"> & {
  id?: string;
  content?: string | ReactElement;
};

const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  children,
  id: _id,
  content,
  ...props
}) => {
  const id = useMemo(() => {
    return (children as ReactElement)?.props?.id ?? _id ?? uuid();
  }, [_id, children]);

  const childrenCount = useMemo(
    () =>
      Children.map(children, (child) => {
        return child;
      })?.filter((i) => i !== null)?.length ?? 0,
    [children],
  );

  const Component = useMemo(() => {
    if (!children) {
      return null;
    }

    if (["number", "string"].includes(typeof children)) {
      return <span id={id}>{children}</span>;
    }

    const _children = children as ReactElement;

    if (childrenCount > 1) {
      return Children.map(_children, (child) => {
        return cloneElement(child, {
          ..._children.props,
          "data-tooltip-id": id,
        });
      });
    }

    return cloneElement(_children, {
      ..._children.props,
      "data-tooltip-id": id,
    });
  }, [children, id, content]);

  if (typeof children === "string") {
    return (
      <>
        <span id={id}>{children}</span>
      </>
    );
  }

  return (
    <>
      {Component}
      {/* @ts-expect-error */}
      {content ? <ReactTooltip id={id} content={content} {...props} /> : null}
    </>
  );
};

export default Tooltip;
