"use client";
import { ProjectConfigProvider } from "@whitelabel-engine/edge-config-local/provider";
import { CookieConsentMemo as CookieConsent } from "@whitelabel-engine/features/CookieConsent";
import useCookieConsent from "@whitelabel-engine/features/CookieConsent/useCookieConsent";
import DynamicConfigProvider from "@whitelabel-engine/features/DynamicConfigProvider";
import HeaderForjConnectButton from "@whitelabel-engine/features/Headers/HeaderForjConnectButton";
import useCookie3 from "@whitelabel-engine/features/hooks/useCookie3";
import useCountryDisclaimer from "@whitelabel-engine/features/hooks/useCountryDisclaimer";
import useGoogleAnalytics from "@whitelabel-engine/features/hooks/useGoogleAnalytics";
import CountryDisclaimerModal from "@whitelabel-engine/features/Modals/CountryDisclaimer";
import { t } from "@whitelabel-engine/i18n";
import type {
  Configuration,
  EdgeConfigValue,
  FeatureProps,
} from "@whitelabel-engine/typings/configuration";
import MenuTiltedIcon from "@whitelabel-engine/ui/Icon/MenuTilted";
import type { RainbowKitTheme } from "@whitelabel-engine/walletkit/providers/Web3RainbowKitProvider";
import Web3RainbowKitProvider, {
  rainbowKitLightTheme,
} from "@whitelabel-engine/walletkit/providers/Web3RainbowKitProvider";
import merge from "lodash.merge";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";

// TODO: make an API call to fetch this
const features = {
  "@whitelabel-engine/ui/Image": "next/image",
  "@whitelabel-engine/ui/Menus/MenuMobile":
    "@whitelabel-engine/ui/Menus/MenuMobileOpenTopShowHeader",
  "@whitelabel-engine/ui/Menus/MenuItem":
    "@whitelabel-engine/ui/Menus/MenuItemSimpleText",
  "@whitelabel-engine/ui/Button":
    "@whitelabel-engine/ui/Button/ButtonIrregularAnimated",
  "@whitelabel-engine/ui/Button/LoadingButton":
    "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated",
};

const theme: RainbowKitTheme = merge(rainbowKitLightTheme(), {
  colors: {
    accentColor: "#FF00FF",
    closeButton: "#FFFFFF",
    modalBackground: "#1B1464",
    modalText: "#FFFFFF",
    modalTextSecondary: "#FF00FF",
    profileAction: "#1B1464",
    profileActionHover: "#FF00FF",
  },
  radii: {
    actionButton: 0,
    modal: 0,
  },
  fonts: {
    body: "FrankNew",
  },
});

export default function Providers({
  children,
  projectConfig,
  projectName,
  walletConfig,
  featuresConfig = features,
}: {
  children: ReactNode;
  projectConfig: EdgeConfigValue;
  projectName: string;
  walletConfig: Configuration["wallets"];
  featuresConfig: FeatureProps;
}) {
  const pathname = usePathname();
  const { consent, showConsentMessage, denyConsent, giveConsent } =
    useCookieConsent();
  const isColdWalletEnabled = Boolean(
    projectConfig?.featureFlags?.enableColdWallet,
  );
  const isCookie3Enabled =
    consent && Boolean(projectConfig?.featureFlags?.enableCookie3);
  const { GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID, COOKIE3_SITE_ID } =
    projectConfig?.config ?? {
      GOOGLE_ANALYTICS_ID: "",
      GOOGLE_TAG_MANAGER_ID: "",
      COOKIE3_SITE_ID: "",
    };

  useGoogleAnalytics(GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID, consent);
  useCookie3(COOKIE3_SITE_ID, isCookie3Enabled);
  const { acceptDisclaimer, showDisclaimer } = useCountryDisclaimer();

  return (
    <ProjectConfigProvider>
      <DynamicConfigProvider features={featuresConfig}>
        <Web3RainbowKitProvider
          appName={projectName}
          chainIds={walletConfig?.chains!}
          jsonRPCChainMap={walletConfig?.rpcNodes!}
          theme={theme}
          // projectId="9ff074335ddadb8b25b74f7b40e0da2f"
          projectId={walletConfig?.walletConnectProjectId!}
        >
          <HeaderForjConnectButton
            className="mx-auto w-full max-w-[1440px]"
            routerPathName={pathname}
            logo="/assets/ForjLogo.svg"
            menuIcon={<MenuTiltedIcon color="light" />}
            delegateCtaLink={
              projectConfig.config?.delegateCtaLink?.toString() ||
              "https://warm.xyz/"
            }
            menuItemsDesktop={[
              { text: t`Launchpad`, href: "/", match: ["^/.*"] },
              { text: t`V2 rewards`, href: "/rewards", match: ["/rewards"] },
              {
                text: t`V1 rewards`,
                href: "https://staking.forj.network/",
                match: [],
              },
            ]}
            isColdWalletEnabled={isColdWalletEnabled}
          />
          {children}
          {showConsentMessage && (
            <CookieConsent
              appName={projectName}
              learnMoreLink="https://forj.network/privacy-policy/"
              onClickClose={denyConsent}
              onClickAccept={giveConsent}
              className="w-full sm:w-fit"
              linkColor="link"
              backgroundColor="primary"
              callToActionColor="light"
              callToActionVariant="contained"
            />
          )}
          <CountryDisclaimerModal
            open={showDisclaimer}
            onClose={acceptDisclaimer}
            backgroundColor="primary"
            color="light"
            borderColor="overlay"
            callToActionColor="light"
            callToActionVariant="contained"
            linkColor="secondary"
            borderRadius="border-none"
            learnMoreLink="https://docs.google.com/document/u/2/d/e/2PACX-1vQwWYiUInpLj3HwlLJ-hD-SomK6YOiHwnvNOhi1uZR4oYKeXgqIv6RuuCMAZ0hwdVxRpVolqQCwkoNu/pub"
          />
        </Web3RainbowKitProvider>
      </DynamicConfigProvider>
    </ProjectConfigProvider>
  );
}
