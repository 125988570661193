import "@rainbow-me/rainbowkit/styles.css";

import type { Theme } from "@rainbow-me/rainbowkit";
import {
  darkTheme,
  lightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  krakenWallet,
  ledgerWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { Chain } from "@wagmi/core/chains";
import { http, WagmiProvider } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";

export type RainbowKitTheme = Theme;
export const rainbowKitDarkTheme = darkTheme;
export const rainbowKitLightTheme = lightTheme;
const apeCurtis = {
  id: 33_111,
  name: "Curtis",
  nativeCurrency: { name: "ApeCoin", symbol: "APE", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://curtis.rpc.caldera.xyz/http"] },
  },
  blockExplorers: {
    default: { name: "Curtis", url: "https://curtis.explorer.caldera.xyz/" },
  },
  contracts: {
    multicall3: {
      address: "0x4DaC255A3b3a0A2CeE0AdD3241482D9063719Bf6",
      blockCreated: 2_771_114,
    },
  },
  custom: {
    iconUrl: "https://cryptologos.cc/logos/apecoin-ape-ape-logo.png?v=031",
    iconBackground: "#fff",
  },
} as const satisfies Chain;
export const approvedChains = [mainnet, sepolia, apeCurtis];

export const configureAppChains = (projectId: string, chains: any) =>
  getDefaultConfig({
    appName: "RainbowKit demo",
    projectId: projectId,
    chains,
    transports: chains?.reduce(
      (acc: any, chain: any) => ({ ...acc, [chain.id]: http() }),
      {},
    ),
    wallets: [
      {
        groupName: "Suggested",
        wallets: [
          metaMaskWallet,
          ledgerWallet,
          walletConnectWallet,
          coinbaseWallet,
          trustWallet,
          krakenWallet,
        ],
      },
    ],
  });

const Web3RainbowKitProvider = ({
  children,
  projectId,
  chainIds,
  theme,
}: {
  children: React.ReactNode;
  appName: string;
  projectId: string;
  chainIds: number[];
  jsonRPCChainMap: Record<string, string>;
  theme?: Theme | null;
}) => {
  try {
    const queryClient = new QueryClient();
    const chains = approvedChains.filter((chain) =>
      chainIds.map(Number).includes(chain.id),
    );

    if (!projectId) {
      throw new Error("Could not find ProjectID! data/relayer will happen.");
    }

    return (
      <WagmiProvider config={configureAppChains(projectId, chains)}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={theme}>{children}</RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    );
  } catch (error) {
    throw error;
  }
};

export default Web3RainbowKitProvider;
