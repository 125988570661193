"use client";
import type { Address } from "@whitelabel-engine/typings/contract";
import { useEffect, useMemo, useState } from "react";

import useActiveWagmi from "./useActiveWagmi";
import useContractReadWithTransactionWatch from "./useContractReadWithTransactionWatch";

const warmDelegateABI = [
  {
    inputs: [{ internalType: "address", name: "hotWallet", type: "address" }],
    name: "getColdWallets",
    outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "coldWallet", type: "address" }],
    name: "getHotWallet",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
] as const;

export const ColdWalletContractAddresses: Record<number, `0x${string}`> = {
  1: "0xC3AA9bc72Bd623168860a1e5c6a4530d3D80456c",
  11155111: "0x050e78c41339DDCa7e5a25c554c6f2C3dbB95dC4",
};

const useColdWallet = (): {
  hotWallet?: Address;
  delegatorWallet?: Address;
  coldWalletList: Address[];
  setDelegatorWallet: (address: Address) => void;
  isLoading: boolean;
  isNetworkSupported: boolean;
  isConnected: boolean;
} => {
  const [delegatorWallet, setDelegatorWallet] = useState<Address>();
  const {
    account: hotWallet,
    isNetworkSupported,
    isConnected,
    chainId,
    chain,
  } = useActiveWagmi();
  const enabled = Boolean(hotWallet);
  const { data, isLoading } = useContractReadWithTransactionWatch(
    {
      abi: warmDelegateABI,
      address:
        ColdWalletContractAddresses[
          chainId as keyof typeof ColdWalletContractAddresses
        ],
      functionName: "getColdWallets",
      args: [hotWallet!],
    },
    enabled,
  );

  const coldWalletList = useMemo(
    () => (Array.isArray(data) ? data?.flat() : []),
    [data],
  );

  const setPersistentDelegatorWallet = (address?: Address) => {
    if (address) {
      setDelegatorWallet(address);
      localStorage.setItem("delegatorWallet", address);
      localStorage.setItem("hotWallet", `${hotWallet}`);
      return;
    }

    setDelegatorWallet(undefined);
    localStorage.removeItem("delegatorWallet");
    localStorage.removeItem("hotWallet");
  };

  useEffect(() => {
    const _hotWallet = localStorage.getItem("hotWallet") as Address;
    if (!hotWallet || (_hotWallet && hotWallet !== _hotWallet)) {
      setPersistentDelegatorWallet(undefined);
      return;
    }

    const delegatorWallet = localStorage.getItem("delegatorWallet") as Address;
    if (delegatorWallet) {
      setDelegatorWallet(delegatorWallet);
    }
  }, [hotWallet]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const delegatorWalletLocalStorage = localStorage.getItem(
        "delegatorWallet",
      ) as Address;
      setDelegatorWallet(
        delegatorWalletLocalStorage === null
          ? undefined
          : delegatorWalletLocalStorage,
      );
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return useMemo(() => {
    return {
      hotWallet,
      delegatorWallet,
      coldWalletList,
      setDelegatorWallet: setPersistentDelegatorWallet,
      isLoading,
      isNetworkSupported,
      isConnected,
      chain,
    };
  }, [
    isLoading,
    hotWallet,
    delegatorWallet,
    coldWalletList,
    isNetworkSupported,
    isConnected,
    chain,
  ]);
};

export default useColdWallet;
