import { t } from "@whitelabel-engine/i18n";
import type { ButtonProps } from "@whitelabel-engine/ui/Button";
import type { DialogProps } from "@whitelabel-engine/ui/Modals/Dialog";
import Dialog from "@whitelabel-engine/ui/Modals/Dialog";
import { type Colors } from "@whitelabel-engine/ui/utils/colors";
import type { FC, MouseEvent } from "react";

import DynamicButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";
import Link from "../Link";

export type CountryDisclaimerProps = DialogProps & {
  open?: boolean;
  title?: string;
  callToActionText?: string;
  message?: string;
  color?: Colors;
  borderColor?: Colors;
  onClose?: (event?: MouseEvent<HTMLButtonElement>) => void;
  learnMoreLink?: string;
  learnMoreText?: string;
  showCloseButton?: boolean;
  callToActionVariant?: ButtonProps["variant"];
  callToActionColor?: ButtonProps["color"];
  linkColor?: Colors;
  borderRadius?: string;
};

const CountryDisclaimerModal: FC<CountryDisclaimerProps> = ({
  backgroundColor = "overlay",
  borderColor = "neutral",
  borderRadius = "rounded-xl",
  callToActionColor,
  callToActionText = t`Accept`,
  callToActionVariant,
  color = "light",
  learnMoreLink = "https://forj.network/risk-summary-uk-residents",
  learnMoreText = t`learn more`,
  linkColor,
  message = t`Don't invest in cryptoassets unless you're prepared to lose all the money you invest. Cryptoassets are a high-risk investment, and you should not expect to be protected if something goes wrong. Take 2 minutes to`,
  onClose,
  opacity = 50,
  open,
  showCloseButton = false,
  title = t`Disclaimer for UK Residents`,
  ...props
}) => {
  const handleOnClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      backgroundColor={backgroundColor}
      wrapperClassName={[
        "max-w-[320px]",
        "sm:max-w-[600px]",
        "flex",
        "flex-col",
        "gap-8",
        "justify-center",
        "items-center",
        "border",
        "p-4",
        borderRadius,
        `border-${borderColor}`,
        `text-${color}`,
      ].join(" ")}
      hideCloseButton
      opacity={opacity}
      {...props}
    >
      <div
        className={[
          "flex",
          "w-full",
          "flex-row",
          showCloseButton ? "justify-between" : "justify-center",
          "gap-4",
        ].join(" ")}
      >
        <span className="text-xl font-bold md:text-2xl">{title}</span>

        {showCloseButton && (
          <DynamicButton
            variant="text"
            onClick={handleOnClose}
            className="!p-2"
          >
            <IconRender icon="Close" width={14} color="light" />
          </DynamicButton>
        )}
      </div>

      <span className="text-sm font-medium md:text-base">
        {message}{" "}
        <Link
          className={[
            "text-primary",
            "font-bold",
            "underline",
            linkColor ? `text-${linkColor}` : "",
          ].join(" ")}
          href={learnMoreLink}
          target="_blank"
        >
          {learnMoreText}
        </Link>
        .
      </span>

      <div className="flex w-full justify-center p-2">
        <DynamicButton
          onClick={handleOnClose}
          variant={callToActionVariant}
          color={callToActionColor}
        >
          {callToActionText}
        </DynamicButton>
      </div>
    </Dialog>
  );
};

export default CountryDisclaimerModal;
