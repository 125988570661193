/* eslint-disable @next/next/no-img-element */
import type { FC, HTMLAttributes } from "react";
import React from "react";

type ImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
};
type StaticImageData = {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
};
type StaticRequire = {
  default: StaticImageData;
};
type StaticImport = StaticRequire | StaticImageData;
type SafeNumber = number | `${number}`;
type ImageLoader = (p: ImageLoaderProps) => string;
type OnLoadingComplete = (img: HTMLImageElement) => void;
const VALID_LOADING_VALUES = ["lazy", "eager", undefined];
type LoadingValue = (typeof VALID_LOADING_VALUES)[number];
type PlaceholderValue = "blur" | "empty";

export type ImageProps = HTMLAttributes<HTMLDivElement> & {
  src: string | StaticImport;
  alt: string;
  width?: SafeNumber | undefined;
  height?: SafeNumber | undefined;
  fill?: boolean | undefined;
  loader?: ImageLoader | undefined;
  quality?: SafeNumber | undefined;
  priority?: boolean | undefined;
  loading?: LoadingValue;
  placeholder?: PlaceholderValue | undefined;
  blurDataURL?: string | undefined;
  unoptimized?: boolean | undefined;
  onLoadingComplete?: OnLoadingComplete | undefined;
  "data-testid"?: string;
};

/**
 * This component will be module-replaced by the whitelabel engine
 */
const Image: FC<ImageProps> = ({ alt, className, ...props }) => {
  // @ts-ignore
  return <img alt={alt} className={className} {...props} />;
};

export default Image;
