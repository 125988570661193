"use client";
import { getWindow } from "@whitelabel-engine/ui/utils/window";
import { useEffect, useRef } from "react";

const useGoogleAnalytics = (scriptId = "", tagId = "", enable = false) => {
  const isMountedRef = useRef(false);

  const addGoogleAnalyticsScript = (onLoad: () => void = () => null) => {
    const script = document.createElement("script");
    script.id = "ga-script";
    script.src = `https://www.googletagmanager.com/gtag/js?id=${scriptId}`;
    script.async = true;
    script.onload = onLoad;
    document.head.appendChild(script);
  };

  const addGoogleTagManagerScript = () => {
    const noScript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    noScript.id = "gtm-noscript";
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagId}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noScript.appendChild(iframe);
    document.body.appendChild(noScript);
  };

  const executeGoogleAnalytics = () => {
    getWindow().dataLayer = getWindow().dataLayer || [];
    function gtag() {
      getWindow().dataLayer.push(arguments);
    }

    // @ts-expect-error
    gtag("js", new Date());
    // @ts-expect-error
    gtag("config", scriptId);
  };

  const executeGoogleTagManager = () => {
    // Google Tag Manager
    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(getWindow(), document, "script", "dataLayer", tagId);
  };

  useEffect(() => {
    if (!isMountedRef.current && enable) {
      addGoogleAnalyticsScript(() => {
        executeGoogleAnalytics();
        executeGoogleTagManager();
      });
      addGoogleTagManagerScript();

      if (!isMountedRef.current) {
        // Your side effect logic here
        // This code will run once after the initial render
        isMountedRef.current = true;
      }
      return;
    }

    document.querySelector("#ga-script")?.remove?.();
    document.querySelector("#gtm-noscript")?.remove?.();
  }, [enable]);
};

export default useGoogleAnalytics;
