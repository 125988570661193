import type { FC, PropsWithChildren, ReactNode } from "react";
import { useMemo } from "react";

import ArrowCircleRight from "../Icon/ArrowCircleRight";
import Image from "../Image";
import type { Colors } from "../utils/colors";

export type HeaderDesktopProps = {
  logo?: ReactNode;
  logoAlt?: string;
  className?: string;
  backgroundColor?: Colors;
  color?: Colors;
  sticky?: boolean;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  "data-testid"?: string;
  enableStickyAlert?: boolean;
  stickyAlertText?: string;
  stickyAlertHref?: string;
  maxContentWidth?: string;
};

const HeaderDesktop: FC<PropsWithChildren<HeaderDesktopProps>> = ({
  logo = "",
  logoAlt = "",
  className = "",
  backgroundColor = "light",
  color,
  sticky = false,
  leftContent = null,
  rightContent = null,
  children = null,
  enableStickyAlert = false,
  stickyAlertText = "",
  stickyAlertHref = "",
  maxContentWidth = "max-w-[1440px]",
  ...props
}) => {
  const classNames = useMemo(() => {
    return [
      "flex",
      "w-full",
      "flex-col",
      "items-center",
      "justify-between",
      "z-20",
      "px-0",
      "py-0",
      `bg-${backgroundColor}`,
      color && `text-${color}`,
      sticky && "sticky",
      sticky && "top-0",
      className,
    ].join(" ");
  }, [className]);
  return (
    <div className={classNames} data-testid={props["data-testid"]}>
      <div className={`w-full ${maxContentWidth}`}>
        <div className="flex min-h-full w-full flex-row justify-between px-6 py-3">
          <div
            className="flex flex-col justify-start"
            data-testid="header-desktop-left-content"
          >
            {logo ? (
              typeof logo === "string" ? (
                <Image
                  className="h-10"
                  src={logo}
                  alt={logoAlt}
                  data-testid="header-desktop-logo"
                />
              ) : (
                logo
              )
            ) : null}
            {leftContent}
          </div>

          <div
            className="flex justify-start"
            data-testid="header-desktop-center-content"
          >
            {children}
          </div>

          <div
            className="flex justify-end"
            data-testid="header-desktop-right-content"
          >
            {rightContent}
          </div>
        </div>
      </div>

      {enableStickyAlert ? (
        <a
          href={stickyAlertHref}
          className="flex h-12 w-full justify-center"
          target="_blank"
        >
          <div className="bg-primary flex h-12 w-full items-center justify-center text-white">
            <span className="mr-1">{stickyAlertText}</span>
            <ArrowCircleRight color="light" />
          </div>
        </a>
      ) : null}
    </div>
  );
};

export default HeaderDesktop;
