import type { FC, MouseEvent, PropsWithChildren, ReactElement } from "react";
import { useEffect, useRef, useState } from "react";
import { Children, cloneElement } from "react";
import { createPortal } from "react-dom";

import Button from "../Button";
import CloseIcon from "../Icon/Close";
import MenuIcon from "../Icon/Menu";
import type { IconProps } from "../Icon/utils";
import type { Colors } from "../utils/colors";
import type { MenuItemProps } from "./MenuItem";

export type MenuMobileOpenTopShowHeaderProps = {
  "data-testid"?: string;
  backgroundColor?: Colors;
  bottomContent?: ReactElement | null;
  className?: string;
  color?: Colors;
  expanded?: boolean;
  menuIcon?: ReactElement<IconProps> | ReactElement;
  menuIconColor?: Colors;
  menuIconHoverColor?: Colors;
  menuIconSize?: number;
  onClick?: (value: string) => void;
  selected?: string | ((value: string) => boolean);
  selectedBackgroundColor?: Colors;
  selectedColor?: Colors;
  sticky?: boolean;
  topContent?: ReactElement | null;
  wrapperClassName?: string;
  renderChildren?: (onClose: Function) => any;
};

const MenuMobileOpenTopShowHeader: FC<
  PropsWithChildren<MenuMobileOpenTopShowHeaderProps>
> = ({
  backgroundColor = "accent",
  bottomContent = null,
  children,
  className,
  color = "light",
  expanded,
  menuIcon,
  menuIconColor = "light",
  menuIconHoverColor = "light",
  menuIconSize = 24,
  onClick,
  selected,
  selectedBackgroundColor = "transparent",
  selectedColor = "light",
  topContent = null,
  wrapperClassName,
  renderChildren,
  ...props
}) => {
  const portalRef = useRef<HTMLElement>(null!);
  const [isExpanded, setIsExpanded] = useState(expanded ?? false);

  const handleOnClick =
    (value: string, _onClick?: (event: MouseEvent<HTMLDivElement>) => void) =>
    (event: MouseEvent<HTMLDivElement>) => {
      onClick?.(value);
      _onClick?.(event);
    };

  const flipExpandedState = (state?: boolean) => () => {
    setIsExpanded(state ?? !isExpanded);
  };

  useEffect(() => {
    if (!portalRef.current) {
      portalRef.current = document.body;
    }
  }, [portalRef.current]);

  return (
    <>
      <div className={`w-fit ${className}`}>
        <Button
          squared
          variant="text"
          onClick={flipExpandedState()}
          data-testid={`menu-mobile-toggle-menu`}
        >
          {menuIcon ??
            (isExpanded ? (
              <CloseIcon
                color={menuIconColor}
                hoverColor={menuIconHoverColor}
                width={menuIconSize}
              />
            ) : (
              <MenuIcon
                color={menuIconColor}
                hoverColor={menuIconHoverColor}
                width={menuIconSize}
              />
            ))}
        </Button>
      </div>

      {portalRef.current &&
        createPortal(
          <>
            <div
              data-testid={`menu-mobile-backdrop`}
              onMouseDown={flipExpandedState(false)}
              onDragStart={flipExpandedState(false)}
              className={[
                "fixed",
                "inset-0",
                "z-[5]",
                "transition-opacity",
                "duration-300",
                "ease-in-out",
                "bg-dark",
                isExpanded ? "opacity-80" : "opacity-0",
                isExpanded ? "translate-y-[64px]" : "translate-y-[-100%]",
              ].join(" ")}
            />

            <div
              data-testid={`menu-mobile-${props["data-testid"]}`}
              className={[
                "bottom-0",
                "duration-300",
                "ease-in-out",
                "fixed",
                "flex-col",
                "flex",
                "flex",
                "gap-2",
                "h-fit",
                "items-center",
                "justify-start",
                "left-0",
                "py-5",
                "top-0",
                "transition-all",
                "w-full",
                "z-[5]",
                `bg-${backgroundColor}`,
                isExpanded ? "opacity-100" : "opacity-0",
                isExpanded ? "translate-y-[64px]" : "translate-y-[-100%]",
                wrapperClassName,
              ].join(" ")}
            >
              {topContent}
              <div
                className="flex h-full w-full flex-col gap-4"
                data-testid="menu-mobile-center-content"
              >
                {renderChildren
                  ? renderChildren(flipExpandedState)
                  : Children.map(
                      // @ts-ignore
                      children?.filter?.((child) =>
                        Boolean(child),
                      ) as ReactElement<MenuItemProps>[],
                      (child: ReactElement<MenuItemProps>) => {
                        return cloneElement(child, {
                          color,
                          selectedColor,
                          selectedBackgroundColor,
                          ...child?.props,
                          expanded: isExpanded,
                          onClick: handleOnClick(
                            child?.props?.name,
                            child?.props?.onClick,
                          ),
                          selected:
                            typeof selected === "string"
                              ? selected === child?.props?.name
                              : selected?.(child?.props?.name),
                        });
                      },
                    )}
              </div>
              {bottomContent}
            </div>
          </>,
          portalRef.current,
        )}
    </>
  );
};

export default MenuMobileOpenTopShowHeader;
