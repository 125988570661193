import type { FC } from "react";

import _BlankIcon from "./_Blank";
import type { IconProps } from "./utils";

const MenuIcon: FC<IconProps> = (props) => {
  return (
    <_BlankIcon viewBox="0 0 20 20" {...props}>
      <path d="M2.16504 4.75001C2.16504 4.28885 2.53888 3.91501 3.00004 3.91501H17C17.4612 3.91501 17.835 4.28885 17.835 4.75001C17.835 5.21117 17.4612 5.58501 17 5.58501H3.00004C2.53888 5.58501 2.16504 5.21117 2.16504 4.75001ZM2.16504 10C2.16504 9.53885 2.53888 9.16501 3.00004 9.16501H17C17.4612 9.16501 17.835 9.53885 17.835 10C17.835 10.4612 17.4612 10.835 17 10.835H3.00004C2.53888 10.835 2.16504 10.4612 2.16504 10ZM2.16504 15.25C2.16504 14.7889 2.53888 14.415 3.00004 14.415H17C17.4612 14.415 17.835 14.7889 17.835 15.25C17.835 15.7112 17.4612 16.085 17 16.085H3.00004C2.53888 16.085 2.16504 15.7112 2.16504 15.25Z" />
    </_BlankIcon>
  );
};

export default MenuIcon;
