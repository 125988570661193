import { usePathname } from "next/navigation";
import { useMemo } from "react";

import type { HeaderItemProps } from "../Headers/types";

const checkForSelectedRoute = (
  pathname: string,
  name: string,
  match?: string[],
) => {
  if (
    pathname === name ||
    (pathname?.length > 1 && name !== "/" && pathname?.includes?.(name))
  ) {
    return name;
  }

  const matchFound = Boolean(
    match?.some((regex) => new RegExp(regex).test(pathname)),
  );

  return matchFound ? name : null;
};

const isSelected = (selectedRoute: string) => (href: string) =>
  selectedRoute === href;

const useIsSelectedHeaderMenuItem = (menuItems: HeaderItemProps[]) => {
  const pathname = usePathname();
  const selectedRoute = useMemo(() => {
    const byName = menuItems
      .filter(({ href }) => checkForSelectedRoute(pathname, href))
      .filter(Boolean);

    if (byName.length) {
      return byName?.[0]?.href;
    }

    const result = menuItems
      .filter(({ href, match }) => checkForSelectedRoute(pathname, href, match))
      .filter(Boolean)?.[0]?.href;

    return result;
  }, [pathname]);

  return { isSelected: isSelected(selectedRoute) };
};

export default useIsSelectedHeaderMenuItem;
