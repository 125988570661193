"use client";
import { getWindow } from "@whitelabel-engine/ui/utils/window";
import { useEffect, useRef } from "react";

const useCookie3 = (siteId = 418, enable = false) => {
  const isMountedRef = useRef(false);

  const addCookie3Script = (onLoad: () => void = () => null) => {
    getWindow().cookie3Options = {
      siteId,
      additionalTracking: true,
      cookielessEnabled: true,
    };

    getWindow()._paq = getWindow()._paq || [];

    const script = document.createElement("script");
    script.id = "cookie3-script";
    script.async = true;
    script.src = `https://cdn.cookie3.co/scripts/analytics/latest/cookie3.analytics.min.js`;
    script.onload = onLoad;
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (!isMountedRef.current && enable) {
      addCookie3Script();

      if (!isMountedRef.current) {
        // Your side effect logic here
        // This code will run once after the initial render
        isMountedRef.current = true;
      }
      return;
    }

    document.querySelector("#cookie3-script")?.remove?.();
  }, [enable]);
};

export default useCookie3;
