import type { ReactNode } from "react";
import React, { createContext, useEffect, useState } from "react";

const defaultContext: {
  projectConfig: {
    featureFlags: string[];
    config: Record<string, string | Record<string, string>>;
  };
  isLoading: boolean;
} = {
  projectConfig: {
    featureFlags: [""],
    config: {},
  },
  isLoading: true,
};

export const ProjectConfigContext = createContext(defaultContext);

export const ProjectConfigProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [projectConfig, setProjectConfig] = useState(
    defaultContext.projectConfig,
  );
  const [isLoading, setLoading] = useState(defaultContext.isLoading);

  useEffect(() => {
    const fetchFeatures = async () => {
      const data = await fetch("/api/features").then((res) => res.json());
      setProjectConfig(data);
      setLoading(false);
    };
    fetchFeatures();
  }, []);

  return (
    <ProjectConfigContext.Provider value={{ projectConfig, isLoading }}>
      {children}
    </ProjectConfigContext.Provider>
  );
};
