import { setCookie as setCookieNext } from "cookies-next";

export function setCookie(name: string, value: string, days: number) {
  if (typeof window === "undefined") {
    return;
  }

  setCookieNext(name, value, {
    expires: new Date(Date.now() + days * (24 * 60 * 60 * 1000)),
    path: "/",
  });
}
