import dynamic from "next/dynamic";
import { type LinkProps } from "next/link";
import type { HTMLAttributes, PropsWithChildren } from "react";
import { type FC, memo, useMemo } from "react";

const fetchLazyLink = (linkType: "a" | "Link") => {
  if (linkType === "a") {
    return function Anchor({
      children,
      href,
      ...props
    }: PropsWithChildren<LinkProps>) {
      return (
        <a href={href?.toString?.()} {...props}>
          {children}
        </a>
      );
    };
  }

  return dynamic(() => import("next/link"));
};

export const getTargetBlankFromHref = (href?: string) =>
  href?.startsWith?.("http") ? "_blank" : undefined;

const Link: FC<
  PropsWithChildren<
    LinkProps &
      HTMLAttributes<HTMLAnchorElement> & {
        target?: string;
        download?: boolean;
      }
  >
> = ({ children, ...props }) => {
  const { href } = props;
  const linkType = href?.toString?.()?.startsWith?.("http") ? "a" : "Link";
  const target = props?.target ?? linkType === "a" ? "_blank" : undefined;
  const Component = useMemo(() => fetchLazyLink(linkType), [linkType]);

  return <Component {...{ target, ...props }}>{children}</Component>;
};

export default Link;

export const LinkMemo = memo(Link);
