"use client";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { t } from "@whitelabel-engine/i18n";
import type { Address } from "@whitelabel-engine/typings/contract";
import type { ButtonIrregularAnimatedProps } from "@whitelabel-engine/ui/Button/ButtonIrregularAnimated";
import LoadingButtonIrregularAnimated, {
  type LoadingButtonIrregularAnimatedProps,
} from "@whitelabel-engine/ui/Button/LoadingButtonIrregularAnimated";
import Text from "@whitelabel-engine/ui/Text";
import Tooltip from "@whitelabel-engine/ui/Tooltip";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import type {
  FontTransform,
  FontWeight,
} from "@whitelabel-engine/ui/utils/fonts";
import { preventStopPropagation } from "@whitelabel-engine/ui/utils/prevent";
import useColdWallet from "@whitelabel-engine/walletkit/hooks/useColdWallet";
import { shortenAddress } from "@whitelabel-engine/walletkit/utils/format";
import {
  Children,
  cloneElement,
  type FC,
  type MouseEvent,
  type PropsWithChildren,
} from "react";

import IconRender from "../IconRender";
import Link from "../Link";

type ConnectForjWalletButtonColdWalletDesktopProps = {
  variant?: LoadingButtonIrregularAnimatedProps["variant"];
  color?: LoadingButtonIrregularAnimatedProps["color"];
  colorError?: LoadingButtonIrregularAnimatedProps["color"];
  colorIcon?: LoadingButtonIrregularAnimatedProps["color"];
  colorIconSelected?: LoadingButtonIrregularAnimatedProps["color"];
  fullWidth?: boolean;
  longMobileText?: boolean;
  hideMobileIcon?: boolean;
  showIcon?: boolean;
  showChildrenWithWrongNetwork?: boolean;
  weight?: FontWeight;
  transform?: FontTransform;
  "data-testid"?: string;
  selectedDelegatorWallet?: Address;
  showDelegateOption?: boolean;
  hasColdWallet?: boolean;
  delegateCtaLink?: string;
};

type ColdWalletListProps = {
  coldWalletList?: Address[];
  selectedDelegatorWallet?: Address;
  setSelectedDelegatorWallet: (address?: Address) => void;
  className?: string;
  icon?: string;
  iconColor?: Colors;
  borderColor?: Colors;
  fullWidth?: boolean;
  variant: ButtonIrregularAnimatedProps["variant"];
  color: ButtonIrregularAnimatedProps["color"];
};

const ColdWalletTooltipInformation: FC<{
  iconColor?: Colors;
  readMoreTooltipLink?: string;
  backgroundColor?: Colors;
  rounded?: string;
  className?: string;
}> = ({
  readMoreTooltipLink = "/",
  iconColor = "placeholder",
  backgroundColor = "overlay",
  rounded = "lg",
  className = "",
}) => {
  return (
    <div
      className={[
        "mr-2",
        "flex",
        "items-center",
        "justify-center",
        "outline-none",
        className,
      ].join(" ")}
    >
      <Tooltip
        clickable
        data-tooltip-place="bottom"
        className={[
          `!bg-${backgroundColor}`,
          `!rounded-${rounded}`,
          "z-[2]",
        ].join(" ")}
        opacity={100}
        content={
          <Text
            className="block max-w-[320px] text-center font-serif outline-none"
            variant="span"
            size="sm"
          >
            {t`Wallet delegation allows you to mint without connecting your main wallet to the dApp. `}
            <Link
              className="ml-1 font-bold underline"
              href={readMoreTooltipLink}
              target="_blank"
            >
              {t`Read more`}
            </Link>
            .
          </Text>
        }
      >
        <IconRender icon="InfoFilled" height={14} color={iconColor} />
      </Tooltip>
    </div>
  );
};

const ColdWalletList: FC<ColdWalletListProps> = ({
  coldWalletList = [`0x${"0".repeat(40)}`],
  selectedDelegatorWallet,
  setSelectedDelegatorWallet,
  icon = "Cold",
  iconColor = "_cold",
  fullWidth,
  variant,
  color,
  className = "",
}) => {
  const handleSetSelectedDelegatorWallet = (value?: Address) => () => {
    setSelectedDelegatorWallet?.(value);
  };

  return (
    <Tooltip
      key={`tooltip-cold-wallet-${selectedDelegatorWallet}`}
      clickable
      noArrow
      data-tooltip-place="bottom"
      className={["z-[25]", "!bg-secondary", "text-light", className].join(" ")}
      content={
        <ul className="flex w-full min-w-[150px] flex-col gap-2">
          <li
            onClick={handleSetSelectedDelegatorWallet(undefined)}
            className="flex cursor-pointer flex-row items-center justify-center gap-2 px-2 py-4 text-sm font-bold"
          >
            {selectedDelegatorWallet === undefined ? (
              <IconRender icon="Check" color="light" width={16} />
            ) : null}
            {t`None`}
          </li>

          {coldWalletList?.map?.((address) => (
            <li
              onClick={handleSetSelectedDelegatorWallet(address)}
              className="flex cursor-pointer flex-row items-center justify-center gap-2 px-2 py-4 text-sm font-bold"
              key={address}
            >
              {selectedDelegatorWallet === address ? (
                <IconRender icon="Check" color="light" width={16} />
              ) : null}
              {shortenAddress(address)}
            </li>
          ))}
        </ul>
      }
    >
      <LoadingButtonIrregularAnimated
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        // data-testid={props?.["data-testid"]}
        size="small"
      >
        {selectedDelegatorWallet ? (
          <IconRender icon={icon} color={iconColor} />
        ) : null}
        <span className="font-bold">
          {selectedDelegatorWallet
            ? shortenAddress(selectedDelegatorWallet)
            : t`No delegator wallet`}
        </span>
      </LoadingButtonIrregularAnimated>
    </Tooltip>
  );
};

const ConnectForjWalletButtonColdWalletDesktop: FC<
  PropsWithChildren<ConnectForjWalletButtonColdWalletDesktopProps>
> = ({
  variant,
  color,
  colorIcon = "light",
  colorIconSelected,
  fullWidth = false,
  longMobileText = false,
  showIcon = false,
  showChildrenWithWrongNetwork = false,
  weight,
  transform = "uppercase",
  children,
  delegateCtaLink = "https://warm.xyz/",
  hideMobileIcon = false,
  hasColdWallet = true,
  ...props
}) => {
  const {
    delegatorWallet: selectedDelegatorWallet,
    coldWalletList,
    setDelegatorWallet,
  } = useColdWallet();

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const loading = authenticationStatus === "loading";
        const ready = mounted && !loading;
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        if (!connected) {
          return (
            <>
              <LoadingButtonIrregularAnimated
                className="hidden md:block"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                data-testid={props?.["data-testid"]}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  preventStopPropagation<HTMLButtonElement>(event);
                  openConnectModal();
                }}
              >
                {showIcon && (
                  <IconRender
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    icon="WalletAdd"
                    width={18}
                  />
                )}
                <Text
                  transform={transform}
                  color="primary"
                  weight={weight}
                >{t`Connect Wallet`}</Text>
              </LoadingButtonIrregularAnimated>
              <LoadingButtonIrregularAnimated
                className="block md:hidden"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                size="small"
                data-testid={props?.["data-testid"]}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  preventStopPropagation<HTMLButtonElement>(event);
                  openConnectModal();
                }}
              >
                {showIcon && (
                  <IconRender
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    icon="WalletAdd"
                    width={18}
                  />
                )}
                <Text
                  transform={transform}
                  color="primary"
                  weight={weight}
                >{t`Connect Wallet`}</Text>
              </LoadingButtonIrregularAnimated>
            </>
          );
        }

        if (chain.unsupported) {
          return showChildrenWithWrongNetwork ? (
            Children.map(children as any[], (child: any) => {
              return cloneElement(child, {
                ...child?.props,
                disabled: true,
              });
            })
          ) : (
            <LoadingButtonIrregularAnimated
              fullWidth={fullWidth}
              variant={variant}
              color={color}
              onClick={openChainModal}
              data-testid={props?.["data-testid"]}
            >
              <Text
                transform={transform}
                color="primary"
                weight={weight}
              >{t`Wrong Network`}</Text>
            </LoadingButtonIrregularAnimated>
          );
        }

        if (loading) {
          return (
            <LoadingButtonIrregularAnimated fullWidth={fullWidth} loading>
              <Text
                transform={transform}
                weight={weight}
                className="hidden md:block"
              >{t`Loading...`}</Text>
            </LoadingButtonIrregularAnimated>
          );
        }

        return (
          children || (
            <>
              {/* DESKTOP */}
              {/* DESKTOP */}
              {/* DESKTOP */}
              {/* DESKTOP */}

              {hasColdWallet && (
                <ColdWalletTooltipInformation
                  className="hidden md:flex"
                  readMoreTooltipLink={delegateCtaLink}
                />
              )}
              {hasColdWallet && (
                <ColdWalletList
                  className="hidden md:block"
                  coldWalletList={coldWalletList}
                  // @ts-expect-error
                  setSelectedDelegatorWallet={setDelegatorWallet}
                  selectedDelegatorWallet={selectedDelegatorWallet}
                  fullWidth={fullWidth}
                  variant={variant}
                  color={color}
                />
              )}

              {hasColdWallet && (
                <div className="mx-2 hidden items-center md:flex">
                  <IconRender icon="LinkConnect" color="placeholder" />
                </div>
              )}

              <LoadingButtonIrregularAnimated
                fullWidth={fullWidth}
                className="hidden md:block"
                variant={variant}
                color={color}
                onClick={openAccountModal}
                data-testid={props?.["data-testid"]}
              >
                <Text transform={transform} weight={weight}>
                  {account.displayName}
                  {account.displayBalance ? ` (${account.displayBalance})` : ""}
                </Text>
              </LoadingButtonIrregularAnimated>

              {/* MOBILE */}
              {/* MOBILE */}
              {/* MOBILE */}
              {/* MOBILE */}

              <LoadingButtonIrregularAnimated
                className="block md:hidden"
                size="small"
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                onClick={openAccountModal}
                data-testid={props?.["data-testid"]}
              >
                {!hideMobileIcon && (
                  <IconRender
                    icon="WalletAdd"
                    color={colorIcon ?? color}
                    hoverColor={colorIconSelected ?? color}
                    width={20}
                  />
                )}
                {longMobileText ? (
                  <Text transform={transform} weight={weight}>
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""}
                  </Text>
                ) : null}
              </LoadingButtonIrregularAnimated>
            </>
          )
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectForjWalletButtonColdWalletDesktop;
