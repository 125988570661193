import { t } from "@whitelabel-engine/i18n";
import type { ButtonProps } from "@whitelabel-engine/ui/Button";
import type { Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC, type HTMLAttributes, memo } from "react";

import DynamicButton from "../Dynamic/LoadingButton";
import IconRender from "../IconRender";
import Link from "../Link";

export type CookieConsentPosition =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "center"
  | "top-left"
  | "top-right"
  | "bottom-center"
  | "bottom-left"
  | "bottom-right";
export type CookieConsentProps = HTMLAttributes<HTMLDivElement> & {
  position?: CookieConsentPosition;
  closeIcon?: string;
  iconColor?: Colors;
  linkColor?: Colors;
  iconSize?: string | number;
  appName?: string;
  learnMoreLink?: string;
  iconHoverColor?: Colors;
  onClickClose?: () => void;
  onClickAccept?: () => void;
  callToActionVariant?: ButtonProps["variant"];
  callToActionColor?: ButtonProps["color"];
  backgroundColor?: Colors;
};

const positionMap: { [key in CookieConsentPosition]: string } = {
  top: "top-0",
  bottom: "bottom-0",
  left: "left-0",
  right: "right-0",
  center: "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
  "top-left": "top-0 left-0",
  "top-right": "top-0 right-0",
  "bottom-center": "bottom-0 left-1/2 transform -translate-x-1/2",
  "bottom-left": "bottom-0 left-0",
  "bottom-right": "bottom-0 right-0",
};

const CookieConsent: FC<CookieConsentProps> = ({
  appName,
  className,
  closeIcon = "Close",
  iconColor = "light",
  linkColor = "primary",
  iconHoverColor,
  iconSize = 16,
  learnMoreLink = "#",
  onClickAccept,
  onClickClose,
  position = "bottom-center",
  callToActionVariant,
  callToActionColor,
  backgroundColor = "background",
}) => {
  return (
    <div
      className={[
        "fixed",
        "w-full",
        "py-2",
        "px-4",
        "sm:px-6",
        "border-[1px]",
        "border-overlay",
        "border-solid",
        "z-10",
        "flex",
        "flex-col",
        "sm:!bottom-4",
        "sm:flex-row-reverse",
        "sm:justify-between",
        "sm:gap-2",
        "sm:rounded",
        `bg-${backgroundColor}`,
        className,
        positionMap[position],
      ].join(" ")}
    >
      <div className="mb-8 mt-4 flex items-center justify-end sm:my-0 sm:ml-4 sm:mr-2">
        <DynamicButton variant="text" onClick={onClickClose}>
          <IconRender
            icon={closeIcon}
            color={iconColor}
            hoverColor={iconHoverColor}
            width={iconSize}
          />
        </DynamicButton>
      </div>

      <div className="flex w-full flex-col sm:min-w-[790px] sm:flex-row sm:items-center">
        <p className="mb-6 block w-full sm:mb-0">
          {appName} {t`uses cookies to give you the best possible experience.`}{" "}
          <Link
            href={learnMoreLink}
            className={["font-bold", "underline", `text-${linkColor}`].join(
              " ",
            )}
            target="_blank"
          >{t`Learn more`}</Link>
        </p>

        <DynamicButton
          variant={callToActionVariant}
          color={callToActionColor}
          onClick={onClickAccept}
        >
          {t`Accept`}
        </DynamicButton>
      </div>
    </div>
  );
};

export default CookieConsent;
export const CookieConsentMemo = memo(CookieConsent);
