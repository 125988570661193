"use client";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";

import { setCookie } from "../utils/cookie";

const useUserConsent = () => {
  const [consent, setConsent] = useState(getCookie("consent") === "true");
  const [showConsentMessage, setShowConsentMessage] = useState(false);

  useEffect(() => {
    if (getCookie("consent") === "true") {
      setConsent(true);
      setShowConsentMessage(false);
      return;
    }
    setShowConsentMessage(true);
  }, []);

  useEffect(() => {
    if (consent) {
      setCookie("consent", "true", 365);
    }
  }, [consent]);

  return {
    consent,
    showConsentMessage,
    denyConsent: () => {
      setConsent(false);
      setShowConsentMessage(false);
    },
    giveConsent: () => {
      setConsent(true);
      setShowConsentMessage(false);
    },
  };
};

export default useUserConsent;
