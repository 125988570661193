import(/* webpackMode: "eager" */ "/app/apps/forj-dapp/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/forj-dapp/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/features/IconRender/index.tsx");
