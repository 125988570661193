"use client";
/* eslint-disable tailwindcss/no-custom-classname */
import Button from "@whitelabel-engine/ui/Button";
import HeaderDesktop from "@whitelabel-engine/ui/Headers/HeaderDesktop";
import HeaderMobile from "@whitelabel-engine/ui/Headers/HeaderMobile";
import Image from "@whitelabel-engine/ui/Image";
import MenuMobile from "@whitelabel-engine/ui/Menus/MenuMobile";
import Text from "@whitelabel-engine/ui/Text";
import type { FC } from "react";
import { useMemo } from "react";

import ConnectForjWalletButton from "../ConnectForjWalletButton";
import ConnectForjColdWalletButton from "../ConnectForjWalletButton/ColdWalletDesktop";
import IconRender from "../IconRender";
import Link from "../Link";
import type { HeaderProps } from "./types";
import useIsSelectedHeaderMenuItem from "./useIsSelectedHeaderMenuItem";

const HeaderForjConnectButton: FC<HeaderProps> = ({
  logo = "https://www.linkpicture.com/q/forjlogo.png",
  menuItemsDesktop = [],
  menuItemsMobile: _menuItemsMobile = [],
  socialMenuItems,
  backgroundColor = "primary",
  menuIcon,
  isColdWalletEnabled = false,
  ...props
}) => {
  const ConnectWalletButton = isColdWalletEnabled
    ? ConnectForjColdWalletButton
    : ConnectForjWalletButton;
  const menuItemsMobile = useMemo(
    () =>
      _menuItemsMobile && _menuItemsMobile?.length
        ? _menuItemsMobile
        : menuItemsDesktop,
    [menuItemsDesktop],
  );
  const { isSelected: isSelectedMobileMenuItem } =
    useIsSelectedHeaderMenuItem(menuItemsMobile);
  const { isSelected: isSelectedDesktopMenuItem } =
    useIsSelectedHeaderMenuItem(menuItemsMobile);

  return (
    <>
      <HeaderMobile
        className="flex md:hidden"
        borderColor="transparent"
        data-testid={`header-mobile-headerforjconnectbutton-${props["data-testid"]}`}
        sticky
        backgroundColor={backgroundColor}
        leftContent={
          <Image
            className="h-10"
            src={logo}
            alt="Logo image"
            data-testid="header-mobile-logo"
            width={110}
            height={32}
          />
        }
        rightContent={
          <MenuMobile
            className="flex"
            menuIconColor="light"
            data-testid="headerforjconnectbutton"
            backgroundColor="secondary"
            selected={isSelectedMobileMenuItem}
            wrapperClassName="!w-full"
            menuIcon={menuIcon}
            renderChildren={(onFlipState) => (
              <>
                <div className="flex w-full items-center justify-between px-4">
                  <Text size="4xl" weight="bold" transform="uppercase">
                    MENU
                  </Text>
                  <Button onClick={onFlipState(false)} variant="text">
                    <IconRender color="light" icon="Close" width={20} />
                  </Button>
                </div>

                <div className="my-8 flex flex-col gap-4 px-4">
                  {menuItemsMobile.map(({ text, href }) => (
                    <Link
                      key={`${text}-${href}`}
                      href={href}
                      onClick={onFlipState(false)}
                    >
                      <Text
                        variant="span"
                        size="2xl"
                        weight="bold"
                        transform="uppercase"
                        color={
                          isSelectedMobileMenuItem(href) ? "primary" : "light"
                        }
                        className="hover:text-primary"
                      >
                        {text}
                      </Text>
                    </Link>
                  ))}
                </div>

                <div className="mt-4 flex w-full justify-center">
                  <ConnectWalletButton
                    color="secondary"
                    data-testid="header-mobile-connect-wallet-button"
                    weight="bold"
                    transform="uppercase"
                    showIcon={false}
                    fullWidth
                    longMobileText
                    hideMobileIcon
                  />
                </div>
              </>
            )}
          />
        }
      />
      <HeaderDesktop
        sticky
        backgroundColor={backgroundColor}
        logo={logo}
        className="!z-20 hidden md:flex"
        data-testid={`header-desktop-headerforjconnectbutton-${props["data-testid"]}`}
        rightContent={
          <ConnectWalletButton
            color="light"
            data-testid="header-desktop-connect-wallet-button"
            weight="bold"
            transform="uppercase"
          />
        }
      >
        <div className="jutify-end mr-10 flex items-center gap-4">
          {menuItemsDesktop.map(({ text, href }) => (
            <Link key={`${text}-${href}`} href={href}>
              <Text
                variant="span"
                size="lg"
                weight="bold"
                color={isSelectedDesktopMenuItem(href) ? "secondary" : "light"}
                className="hover:text-secondary"
              >
                {text}
              </Text>
            </Link>
          ))}
        </div>
      </HeaderDesktop>
    </>
  );
};

export default HeaderForjConnectButton;
