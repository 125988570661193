"use client";

import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";

const COUNTRY_DISCLAIMER_LOCAL_KEY = "countryDisclaimer";

const useCountryDisclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  const acceptDisclaimer = () => {
    localStorage.setItem(COUNTRY_DISCLAIMER_LOCAL_KEY, "true");
    setShowDisclaimer(false);
  };

  useEffect(() => {
    const countryCookie = getCookie("NEXT_COUNTRY");
    const acceptedTerms = localStorage.getItem(COUNTRY_DISCLAIMER_LOCAL_KEY);
    if (countryCookie === "GB" && !acceptedTerms) {
      console.log({ countryCookie, acceptedTerms });
      setShowDisclaimer(true);
    }
  }, []);

  return {
    showDisclaimer,
    acceptDisclaimer,
  };
};

export default useCountryDisclaimer;
