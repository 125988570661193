import type { PropsWithChildren, ReactNode } from "react";
import { useMemo } from "react";

import ArrowCircleRight from "../Icon/ArrowCircleRight";
import Image from "../Image";
import type { Colors } from "../utils/colors";

export type HeaderMobileProps = {
  logo?: ReactNode;
  logoAlt?: string;
  className?: string;
  backgroundColor?: Colors;
  borderColor?: Colors;
  color?: Colors;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  sticky?: boolean;
  "data-testid"?: string;
  enableStickyAlert?: boolean;
  stickyAlertText?: string;
  stickyAlertHref?: string;
};

function HeaderMobile({
  logo = "",
  logoAlt = "",
  className = "",
  backgroundColor = "light",
  borderColor = "light",
  color,
  leftContent = null,
  rightContent = null,
  children = null,
  sticky = false,
  enableStickyAlert = false,
  stickyAlertText = "",
  stickyAlertHref = "",
  ...props
}: PropsWithChildren<HeaderMobileProps>) {
  const classNames = useMemo(() => {
    return [
      "flex",
      "h-16",
      "w-screen",
      "flex-row",
      "items-center",
      "justify-between",
      "z-10",
      "border-[1px]",
      "border-solid",
      "px-2",
      "py-1",
      "md:px-4",
      borderColor ? `border-${borderColor}` : null,
      backgroundColor ? `bg-${backgroundColor}` : null,
      color ? `text-${color}` : null,
      sticky ? "sticky top-0" : null,
      className,
    ]
      .filter(Boolean)
      .join(" ");
  }, [className, borderColor, backgroundColor, color, sticky]);

  return (
    <>
      <div className={classNames} data-testid={props["data-testid"]}>
        <div
          className="flex flex-1 justify-start"
          data-testid="header-mobile-left-content"
        >
          {leftContent}
        </div>

        {children || logo ? (
          <div
            className="flex flex-1 justify-center"
            data-testid="header-mobile-center-content"
          >
            {logo ? (
              typeof logo === "string" ? (
                <Image
                  className="h-10"
                  src={logo}
                  alt={logoAlt}
                  data-testid="header-mobile-logo"
                />
              ) : (
                logo
              )
            ) : null}
            {children}
          </div>
        ) : null}

        <div
          className="flex flex-1 justify-end"
          data-testid="header-mobile-right-content"
        >
          {rightContent}
        </div>
      </div>

      {enableStickyAlert ? (
        <a
          href={stickyAlertHref}
          target="_blank"
          className="flex h-11 w-full justify-center md:hidden"
        >
          <div className="bg-primary flex h-11 w-full items-center justify-center text-white">
            <span className="mr-1">{stickyAlertText}</span>
            <ArrowCircleRight color="light" />
          </div>
        </a>
      ) : null}
    </>
  );
}

export default HeaderMobile;
